import { SystemData, ConfigValues } from "Interfaces/JsonData.js";

const GameplayData = {
    score: 0,
    total: 0,
    playing: false,
    elapsedTime: 0
}
const ObserversGameplayData = {
    score: [],
    total: [],
    playing: [],
    elapsedTime: []
}

export function GetGameplayData(key){
    return GameplayData[key];
}

export function SetGameplayData(key, value){
    GameplayData[key] = value;
    ObserversGameplayData[key].forEach((element) => {
        element(value);
    })
}

export function SubscribeGameplayData(key, func){
    ObserversGameplayData[key].push(func);
}


export function VerifyCatchItem(input){
    if(input < 49 || input > 53){
        return;
    }

    const currentTrack = input - 49;
    
    Object.values(SystemData.collectableItemsData).forEach((item) => {
        if(item.track == currentTrack){
            // console.log(item);
            const transitionOffset = document.getElementById(item.id).style.transform.split(/\w+\(|\);?/);
            const Yposition = transitionOffset[1].split("px, ");
            const PosY = parseInt(Yposition[1]);
            const MinLimit = (SystemData.height * 0.6) - 40;
            const MaxLimit = (SystemData.height * 0.7);            
            if(PosY > MinLimit && PosY < MaxLimit ){  
                SetGameplayData("score", GameplayData.score + 1);         
                document.getElementById(item.id).remove();
                const IdIndex = SystemData.collectableItemsIDs.indexOf(item.id);
                if (IdIndex > -1) {
                    SystemData.collectableItemsIDs.splice(IdIndex, 1);
                    delete SystemData.collectableItemsData[item.id];
                }
            }                
        }        
    });

}