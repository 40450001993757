import React, { useState, useEffect, useRef } from "react";

import './App.css';

import InputStripe from "Components/InputStripe"
import { SpawnCollectableItem } from "Services/Spawner.js";
import { SystemData, ConfigValues } from "Interfaces/JsonData.js";
import { VerifyCatchItem, SubscribeGameplayData, SetGameplayData, GetGameplayData } from "Services/Gameplay.js";

function App() {  

  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);
  const [playing, setPlaying] = useState(false);
  
  const Canvas = useRef();

  function HandleScreenSize(){
    const aspect = window.innerWidth / window.innerHeight;

    if(aspect >= 1){
      Canvas.current.style.width = window.innerHeight + "px";
      Canvas.current.style.height = window.innerHeight + "px";
      SystemData.height = window.innerHeight;
    }else{
      Canvas.current.style.width = window.innerWidth + "px";
      Canvas.current.style.height = window.innerWidth + "px";
      SystemData.height = window.innerWidth;
    }   
  }

  function SelectSpawn(){
    if(!GetGameplayData("playing")){
      return;
    }
    const timeout = (1 / ConfigValues.CollectableItemFrequency);
    const currentGameTime = GetGameplayData("elapsedTime") + timeout;   
    if(ConfigValues.GameDuration < currentGameTime){
      return;
    }
    SetGameplayData("elapsedTime", currentGameTime);    
    if(currentGameTime < ConfigValues.InitialMessage){
      document.getElementById("InitialMessage").style.display = "flex";
    }else{
      const TotalSpawned = GetGameplayData("total") + 1;
      SetGameplayData("total", TotalSpawned);
      document.getElementById("InitialMessage").style.display = "none";
      SpawnCollectableItem(); 
    }   
    if(GetGameplayData("playing")){
      setTimeout(()=>{      
        SelectSpawn();
      },timeout * 1000);
    }
  }

  function StartGame(){
      if(GetGameplayData("playing")){
        return;
      }
      SetGameplayData("playing", true);
      SetGameplayData("total", 0);
      SetGameplayData("score", 0);
      SetGameplayData("elapsedTime", 0);
      Object.values(SystemData.collectableItemsData).forEach((item) => {
        const IdIndex = SystemData.collectableItemsIDs.indexOf(item.id);
        if (IdIndex > -1) {
            document.getElementById(item.id).remove();
            SystemData.collectableItemsIDs.splice(IdIndex, 1);
            delete SystemData.collectableItemsData[item.id];
        }
      });      
      SelectSpawn();
    
  }

  function StopGame(){
      if(!GetGameplayData("playing")){
        return;
      }
      SetGameplayData("playing", false);
      SetGameplayData("total", 0);
      SetGameplayData("score", 0);
      SetGameplayData("elapsedTime", 0);
      document.getElementById("InitialMessage").style.display = "none";
      Object.values(SystemData.collectableItemsData).forEach((item) => {
        const IdIndex = SystemData.collectableItemsIDs.indexOf(item.id);
        if (IdIndex > -1) {
            document.getElementById(item.id).remove();
            SystemData.collectableItemsIDs.splice(IdIndex, 1);
            delete SystemData.collectableItemsData[item.id];
        }
      });
    
  }

  useEffect(() => {
    HandleScreenSize();
    window.addEventListener('resize', HandleScreenSize);
     

    document.addEventListener('keydown', function(event) {      
      VerifyCatchItem(event.keyCode);          
    });

    SubscribeGameplayData("score", setScore);
    SubscribeGameplayData("total", setTotal);
    SubscribeGameplayData("playing", setPlaying);
    
  },[]);

  return (
    <div className="App">
      <div className="Canvas" id="Canvas" ref={Canvas}>
        <InputStripe />

        <div style={{position: 'absolute', top: 0, left: "33.33%", width: "2px", height: "100%", background: "#000"}}></div>
        <div style={{position: 'absolute', top: 0, left: "40%", width: "2px", height: "100%", background: "#000"}}></div>
        <div style={{position: 'absolute', top: 0, left: "46.66%", width: "2px", height: "100%", background: "#000"}}></div>
        <div style={{position: 'absolute', top: 0, left: "53.33%", width: "2px", height: "100%", background: "#000"}}></div>
        <div style={{position: 'absolute', top: 0, left: "60%", width: "2px", height: "100%", background: "#000"}}></div>
        <div style={{position: 'absolute', top: 0, left: "66.66%", width: "2px", height: "100%", background: "#000"}}></div>

        <div style={{position: 'absolute', top: "3%", left: "20px", background: "#FFF", color: "#000"}}>Score: {score}</div>    
        <div style={{position: 'absolute', top: "5%", left: "20px", background: "#FFF", color: "#000"}}>Total: {total}</div>

        <div style={{position: 'absolute', top: "10%", left: "20px", width: "15%", height: "4%", background: "#000", color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={()=>{StartGame()}}>START</div>
        <div style={{position: 'absolute', top: "16%", left: "20px", width: "15%", height: "4%", background: "#000", color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}} onClick={()=>{StopGame()}}>RESET</div>

        <div id="InitialMessage" style={{position: 'absolute', top: "33%", left: "0px", width: "100%", height: "33%", background: "#000", color: "#FFF", display: "none", justifyContent: "center", alignItems: "center", textAlign: "center"}} onClick={()=>{StopGame()}}>
          Otimize sua frota ao máximo!<br />
          Descubra como os serviços e produtos Volvo garantem maior produtividade e rentabilidade para seus negócios.
        </div>

      </div>
    </div>
  );
}

export default App;
