import * as dat from 'dat.gui';
import { gsap } from "gsap";

export const JsonData = {
    
};

export const SystemData = {
    height: 0,
    collectableItemsIDs: [],
    collectableItemsData: {}
};

export const ConfigValues = {
    CollectableItemSpeed: 100,
    CollectableItemFrequency: 0.5,
    GameDuration: 180,
    InitialMessage: 10
}

let gui;

export function InitDatGUI(){
    gui = new dat.GUI();
    gui.closed = false;
    gsap.defaults({
        ease: "linear", 
        duration: 1
    });

    gui.add( ConfigValues, 'CollectableItemSpeed', 10, 500 ).name('Item speed').onChange( function ( value ) {
        ConfigValues.CollectableItemSpeed = value;
    });
    gui.add( ConfigValues, 'CollectableItemFrequency', 0.1, 5 ).name('Item frequency').onChange( function ( value ) {
        ConfigValues.CollectableItemFrequency = value;
    });
    gui.add( ConfigValues, 'GameDuration', 30, 420 ).name('Game duration').onChange( function ( value ) {
        ConfigValues.GameDuration = value;
    });
    gui.add( ConfigValues, 'InitialMessage', 2, 30 ).name('Initial message duration').onChange( function ( value ) {
        ConfigValues.InitialMessage = value;
    });
}
