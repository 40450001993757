import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from "axios";

import { JsonData, InitDatGUI } from "Interfaces/JsonData.js";

const root = ReactDOM.createRoot(document.getElementById('root'));

Axios.get(`https://sebraelideresmovimento.com.br/data_platform.json`).then(({data}) => {      

  for (const key in data) {
    JsonData[key] = data[key];
  } 

  InitDatGUI();
  
  root.render(
    <React.Fragment>
      <App />
    </React.Fragment>
  );


});


