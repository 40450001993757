import { renderToString } from 'react-dom/server';
import CollectableItem from "Components/CollectableItem";
import { SystemData, ConfigValues } from "Interfaces/JsonData.js";
import { GetGameplayData } from "Services/Gameplay.js";
import { gsap } from "gsap";

function CalculateDuration(){
    const duration = 500 / ConfigValues.CollectableItemSpeed;
    return duration;
}

function RandomInRange(Min, Max){
    let result = Math.floor(Math.random() * (Max - Min) + Min);
    while(result == Max){
        result = Math.floor(Math.random() * (Max - Min) + Min);
    }
    return result;
}

function RandomTrackPosition(track){   
    const MinOffset = SystemData.height * 0.37;
    const MaxOffset = SystemData.height * 0.71;
    const TrackDistance = Math.floor((MaxOffset - MinOffset) * 0.2);
    return MinOffset + (TrackDistance * track);
}

export function SpawnCollectableItem(){
    if(!GetGameplayData("playing")){
        return;
    }
    const AnimDuration = CalculateDuration();
    const size = Math.floor(SystemData.height * 0.05);
    const AnimOffsetX = Math.floor(size * 0.5);
    const TopOffset = SystemData.height * 0.2;
    const SpawnInTrack = Math.floor(RandomInRange(0, 5));
    const LeftOffset = RandomTrackPosition(SpawnInTrack);
    const randonNumber = (Math.floor(Math.random() * 100000000)).toString();
    const NewElement = renderToString(<CollectableItem elementID={randonNumber} TopOffset={TopOffset} LeftOffset={LeftOffset} />);
    const frag = document.createRange().createContextualFragment(NewElement);
    
    document.getElementById("Canvas").appendChild(frag); 
    
    SystemData.collectableItemsIDs.push(randonNumber);
    SystemData.collectableItemsData[randonNumber] = {
        track: SpawnInTrack,
        id: randonNumber
    }

    gsap.to(document.getElementById(randonNumber), { duration: AnimDuration, x: "-="+AnimOffsetX, y: SystemData.height, width: "+="+size, height: "+="+size, onComplete(){
        if(document.getElementById(randonNumber)){
            document.getElementById(randonNumber).remove();
            const IdIndex = SystemData.collectableItemsIDs.indexOf(randonNumber);
            if (IdIndex > -1) {
                SystemData.collectableItemsIDs.splice(IdIndex, 1);
                delete SystemData.collectableItemsData[randonNumber];
            }
        }
    } });
}